import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractWindow} from '../../abstract/AbstractWindow';
import {Person} from '../../../model/Person';
import {Corporate} from '../../../model/Corporate';
import {PersonService} from '../../services/YaagoServices/PersonService';
import {CorporateEndpoint} from '../../endpoints/YaagoEndpoints/CorporateEndpoints';
import {IntercomService} from '../../endpoints/IntercomService';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../services/UtilsService';
import {Router} from '@angular/router';
import {LoaderService} from '../../services/LoaderService';
import {FirebaseService} from '../../endpoints/FirebaseService';
import {ThreadSearchRequest} from '../../../model/thread/ThreadSearchRequest';
import {finalize, takeUntil} from 'rxjs/operators';
import {ThreadEndpoint} from '../../endpoints/YaagoEndpoints/ThreadEndpoint';
import {Modules, Permissions} from '../../../model/PlatformRole';
import {PersonEndpoint} from "../../endpoints/YaagoEndpoints/PersonEndpoint";
import {SnackbarService} from "../../services/SnackbarService";
import {SnackbarMessage} from "../../../model/snackbar/SnackbarMessage";
import {IgloohomeEndpoint} from "../../endpoints/PartnerEndpoints/IgloohomeEndpoint";
import {CorporateIgloohomeConnect} from "../../../model/partners/igloohome/CorporateIgloohomeConnect";
import {ReleaseNoteEndpoint} from "../../endpoints/YaagoEndpoints/ReleaseNoteEndpoint";
import {IncidentNote} from "../../../model/release/IncidentNote";
import {BookingDotComEndpoint, ChallengeSendCode} from "../../endpoints/OtaServices/BookingDotComEndpoint";
import {BookingDotComAccount} from "../../../model/bookingdotcom/BookingDotComAccount";
import {GenericLinkEndpoint} from "../../endpoints/V3_GenericLink/GenericLinkEndpoint";

@Component({
  selector: 'app-yaago2-topnavbar',
  templateUrl: './yaago2-topnavbar.component.html',
  styleUrls: ['./yaago2-topnavbar.component.scss']
})
export class Yaago2TopnavbarComponent extends AbstractWindow implements OnInit {

  Permissions: Permissions;
  openFullMenu = false;
  currentMobileTab = '';
  currentNavMenuItem = null;
  myProfile: Person;
  incidentNotes: IncidentNote[] = [];
  myCorporate: Corporate;
  myCorporateOwner: Person;
  menuOpen = false;
  @Input() tabs: NavMenuItem[] = [];
  subItemsTabs: NavMenuItem[] = [];
  subItemsProfileTabs: NavMenuItem[] = [];
  subItemTabSelected: NavMenuItem[];
  subItemsTasksTabs: NavMenuItem[] = [];
  dubItemsBookingTabs: NavMenuItem[] = [];
  level = 0;
  @Output() clickOnSelectedItem = new EventEmitter<any>();
  currentThreadCount = 0;
  currentPendingGenericLink = 0;
  corporateIgloohomeConnect: CorporateIgloohomeConnect = null;
  bookingDotComChallenge: BookingDotComAccount[] = [];
  demandalreadyasked = false;
  challengeCode: string;

  permissions = Permissions;

  constructor(
            private router: Router,
            private threadEndpoint: ThreadEndpoint,
            private genericLinkEndpoint: GenericLinkEndpoint,
            private firebaseService: FirebaseService,
            private loaderService: LoaderService,
            private personService: PersonService,
            private personEndpoint: PersonEndpoint,
            private corporateEndpoint: CorporateEndpoint,
            private igloohomeEndpoint: IgloohomeEndpoint,
            private bookingDotComEndpoint: BookingDotComEndpoint,
            public intercomService: IntercomService,
            public translateService: TranslateService,
            public snackbarService: SnackbarService,
            private releaseNoteEndpoint: ReleaseNoteEndpoint,
            public utilsService: UtilsService) {
    super(intercomService, translateService, utilsService);
  }


  ngOnInit(): void {
    this.myProfile = this.personService.getCurrentUser();


    this.bookingDotComEndpoint.V3GetBookingDotComChallenge().subscribe( res => {
      // console.log("V3GetBookingDotComChallenge", res);
      this.bookingDotComChallenge = res;
    });

    this.igloohomeEndpoint.getIgloohomeConnect(this.myProfile.corporateId).subscribe( res => {
      this.corporateIgloohomeConnect = res;
      // console.log("corporateIgloohomeConnect", this.corporateIgloohomeConnect);
      this.manageTabs();
    });

    this.translateService.onLangChange.subscribe(() => {
      this.manageTabs();
    });

    this.corporateEndpoint.getCorporateById(this.myProfile.corporateId).subscribe( res => {
      // console.log("MYYYYYYY Corporate", res)
      this.myCorporate = res;

      this.personEndpoint.getMyCorporateOwner().subscribe( res => {
        this.myCorporateOwner = res;

        this.manageTabs();

        if (this.isDesktopMode()) {
          this.calculateHeaderLevel();
        }

      });


    });



    const threadsearch: ThreadSearchRequest = new ThreadSearchRequest();
    threadsearch.pageNumber = 0;
    threadsearch.pageSize = 1;
    threadsearch.onlyWithUnreadMessages = true;
    threadsearch.propertyTextSearch  = '';
    threadsearch.bookingTextSearch = '';
    threadsearch.recipientType = 'Guest';
    this.threadEndpoint.searchThreadsNew(threadsearch)
      .pipe(takeUntil(this.onDestroy),
        finalize(() => this.loaderService.dismissLoader()))
      .subscribe( res => {
        this.currentThreadCount = res.threadCount;
      });
    this.genericLinkEndpoint.countPendingGenericLink()
      .pipe(takeUntil(this.onDestroy),
        finalize(() => this.loaderService.dismissLoader()))
      .subscribe( res => {
        console.log("countPendingGenericLink", res);
      this.currentPendingGenericLink = res;
      });
  }

  calculateHeaderLevel() {
    this.level = 0
    if (this.mustShowEmailValidationHeader()) {
      this.level = this.level + 1;
    }
    if (this.isVatConcern()) {
      this.level = this.level + 1;
    }
    if (this.bookingDotComChallenge.length>0) {
      this.level = this.level + 1;
    }
    if (this.mustShowTrialHeader()) {
      this.level = this.level + 1;
    }

    this.releaseNoteEndpoint.getIncidentNotesWithLanguage(this.myProfile.language).subscribe( res => {
      this.incidentNotes = res;
      if (this.incidentNotes?.length > 0) {
        this.level = this.level + 1;
        // console.log("Incident Notes", this.incidentNotes);
        // console.log("Level:", this.level);
        this.manageLevel();
      }
    });
    // console.log ("Level:", this.level);
    this.manageLevel();

  }

  manageLevel() {
    if (this.level == 1) {
      document.documentElement.style.setProperty('--top-menu-height', '115px');
    } else if (this.level == 2) {
      document.documentElement.style.setProperty('--top-menu-height', '160px');
    } else if (this.level == 3) {
      document.documentElement.style.setProperty('--top-menu-height', '205px');
    } else if (this.level == 4) {
      document.documentElement.style.setProperty('--top-menu-height', '250px');
    } else if (this.level == 5) {
      document.documentElement.style.setProperty('--top-menu-height', '295px');
    }
  }



  mustShowTrialHeader(): boolean {
    return ((this.myProfile.hasPaymentMethod && this.utilsService.diffDays(this.myProfile.trialPeriodOverDate) >= 0) || (!this.myProfile.hasPaymentMethod && !this.myProfile.trialPeriodOver)
      && this.myProfile.corporateOwner
      && !this.myProfile.beta);
  }
  mustShowEmailValidationHeader(): boolean {
    return this.utilsService.hasPendingEmailValidation(this.myCorporate);
  }

  mustShowAlertHeader(): boolean {
    let showAlert = false;
    this.releaseNoteEndpoint.getIncidentNotes().subscribe( res => {
      this.incidentNotes = res;
      if (this.incidentNotes?.length > 0) {
        console.log("Incident Notes", this.incidentNotes);
        showAlert = true;
      }
    });
    return showAlert;
  }

  manageTabs() {
    this.tabs = [];
    this.subItemsProfileTabs = [];
    this.subItemsTabs = [];
    this.subItemsTasksTabs = [];
    this.dubItemsBookingTabs = [];

    if (this.myProfile.influencerDetails == null) {
      
      if (this.myCorporateOwner?.subscriptionName !== 'premium' && this.myCorporateOwner?.subscriptionName !== 'yaago_mission' && this.myCorporateOwner?.subscriptionName !== 'yaago_360_eng' && this.myCorporateOwner?.subscriptionName !== 'yaago_360' && this.utilsService.hasPermission(Permissions.TASKS_LISTING, null)) {
        const menuTaskManagement = new NavMenuItem(this.translateService.instant('cc3-nav-bar.tasks-management'), 'tasks-management', false)
        this.subItemsTasksTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.tasks-list'), 'dashboard', false, false));
        this.subItemsTasksTabs.push(new NavMenuItem('sep', 3, false));

        if (this.utilsService.hasPermission(Permissions.TASKS_ASSIGNING, null) && this.utilsService.hasPermission(Permissions.SETTINGS_USERS_ROLES_MANAGEMENT, null)) {
          this.subItemsTasksTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.affectation-management'), 'assign', false, false));
        }


        if(this.myCorporateOwner?.subscriptionName !== 'yaago_voyageur'){
          menuTaskManagement.subItems = this.subItemsTasksTabs;
        this.tabs.push(menuTaskManagement);
        }

        
      } else if (this.myCorporateOwner?.subscriptionName !== 'success' && this.utilsService.hasPermission(Permissions.TASKS_LISTING, null)) {
        const menuTaskManagement = new NavMenuItem(this.translateService.instant('cc3-nav-bar.tasks-management'), 'tasks-management', false)
        this.subItemsTasksTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.tasks-list'), 'dashboard', false, false));
        this.subItemsTasksTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.tasks-calendar'), 'calendar-tasks', false, false));
        this.subItemsTasksTabs.push(new NavMenuItem('sep', 3, false));

        if (this.utilsService.hasPermission(Permissions.TASKS_ASSIGNING, null) && this.utilsService.hasPermission(Permissions.SETTINGS_USERS_ROLES_MANAGEMENT, null))  {
          this.subItemsTasksTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.affectation-management'), 'assign', false, false));
        }
        if (this.myCorporate?.betaTesterForBilling) {
          this.subItemsTasksTabs.push(new NavMenuItem('sep', 4, false));
          this.subItemsTasksTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.billing-management'), 'billing', false, false));
        }
        menuTaskManagement.subItems = this.subItemsTasksTabs;
        this.tabs.push(menuTaskManagement);
      }

      if (this.utilsService.hasPermission(Permissions.PROPERTY_LISTING, null)) {
        this.tabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.properties'), 'property', false));
      }

      if (this.myCorporateOwner?.subscriptionName !== 'success' && this.utilsService.hasPermission(Permissions.BOOKING_CALENDARS, null)) {
        this.tabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.calendars'), 'calendar', false));


        const bookingManagement = new NavMenuItem(this.translateService.instant('cc3-nav-bar.bookings'), 'booking-management', false)
        this.dubItemsBookingTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.bookings-list'), 'bookings', false, false));
        if (this.utilsService.hasPermission(Permissions.BOOKING_VALIDATION, null)) {
          this.dubItemsBookingTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.generic-bookings-list'), 'generic-bookings', false, false));
        }
        bookingManagement.subItems = this.dubItemsBookingTabs;
        this.tabs.push(bookingManagement);
        // this.tabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.bookings'), 'bookings', false));
      }

      // this.tabs.push(new NavMenuItem("Clients", 3, false));
      // this.tabs.push(new NavMenuItem("Communication", 3, false));

    } else {
      this.tabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.address'), 'recommendation', false));
      this.tabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.my-profile'), 'profile', false));
    }

    if (this.myProfile.influencerDetails == null) {
      this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.my-profile'), 'profile', false));
      if (this.utilsService.hasPermission(Permissions.SETTINGS_SUBSCRIPTION_VIEW, null)) {
        this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.my-referral'), 'referral', false));
      }
      if (this.utilsService.hasPermission(Permissions.SETTINGS_SUBSCRIPTION_VIEW, null)) {
        this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.my-subscription'), 'abonnement', false));
      }

      if (this.utilsService.hasPermission(Permissions.SETTINGS_ACCOUNT_PARAMETERS, null)) {
        this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('settings.config-profiles'), 'accommodation-settings', false));
      }

      if (this.utilsService.hasPermission(Permissions.SETTINGS_SUBSCRIPTION_VIEW, null)) {
        this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.statistics'), 'stats', false));
      }

      this.subItemsProfileTabs.push(new NavMenuItem('sep', 3, false));

      if (this.utilsService.hasPermission(Permissions.SETTINGS_USERS_ROLES_MANAGEMENT, null)) {
        if (!this.utilsService.hasPermission(Permissions.SETTINGS_ROLE_ONLY_MYTEAM, null)) {
          this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.role-management'), 'roles', false, false));
        }
        if (!this.utilsService.hasPermission(Permissions.SETTINGS_ROLE_ONLY_MYTEAM, null)) {
          this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.team-management'), 'teams', false, false));
        }
        // this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.user-management'), 'users', false, false));
        this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.user-management'), 'new-users', false, false, false, false));
        if (this.utilsService.hasPermission(Permissions.TASKS_ASSIGNING, null)) {
          this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.affectation-management'), 'assign', false, false));
        }

        this.subItemsProfileTabs.push(new NavMenuItem('sep', 3, false));
      }
    }

    //this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('nav-bar.help'), 'aide', false));
    if (this.utilsService.hasPermission(Permissions.SETTINGS_NO_SUPPORT, null)) {
      this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.support'), 'support', false));
    }
    this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.academy'), 'academy', false));
    this.subItemsProfileTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.logout'), 'logout', false));
    this.subItemsProfileTabs.push(new NavMenuItem('ver', 3, false));

    // this.subItemsTabs.push(new NavMenuItem("Gestion des logements", 5, false));

    if (this.utilsService.hasPermission(Permissions.SETTINGS_RECOMMENDATIONS, null) && this.myCorporateOwner?.subscriptionName !== 'yaago_mission') {
      this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.address'), 'recommendation', false) );
      this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.address-list'), 'recommendation-list', false));
    }
    if (this.utilsService.hasPermission(Permissions.SETTINGS_PLATFORM_CONNECTIONS, null) ||
      this.utilsService.hasPermission(Permissions.SETTINGS_PROPERTY_OWNER_OTA_MANAGEMENT, null)
    ) {
      this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.platform-connexion'), 'platforms', false));
    }

    if (this.utilsService.hasPermission(Permissions.SETTINGS_KEYRING_MASS_SETUP, null) && this.myCorporateOwner?.subscriptionName !== 'yaago_mission') {
      this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.keyring'), 'keyring', false, false, true));
    }
    if (this.utilsService.hasPermission(Permissions.SETTINGS_KEYRING_MASS_SETUP, null) && this.myCorporateOwner?.subscriptionName !== 'yaago_mission') {
      this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.masterlock'), 'masterlock', false, false, false));
    }

    if (this.utilsService.hasPermission(Permissions.SETTINGS_CONNECTED_SERVICES, null)
      && this.myProfile?.corporate.swiklyLinked) {
      this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.bulk-swikly'), 'bulk-swikly', false, false, true));
    }

    if (this.myCorporateOwner?.subscriptionName !== 'success') {
      if (this.utilsService.hasPermission(Permissions.SETTINGS_CONNECTED_SERVICES, null) ||
        this.utilsService.hasPermission(Permissions.PROPERTY_DASHBOARD_CONNECTED_DEVICES, null)) {
        this.subItemsTabs.push(new NavMenuItem('sep', 1, false));
      }

      if (this.utilsService.hasPermission(Permissions.BOOKING_ONLINE_CHECK, null, Modules.CHECKIN_ONLINE) && this.myCorporateOwner?.subscriptionName !== 'yaago_mission') {
        this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.online-check'), 'checkin-online', false));
      }

      if (!this.utilsService.hasPermission(Permissions.BOOKING_UPSELLS_NO_VIEW, null)) {
        if (this.myProfile.corporate.stripeAccountId != null) {
          this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.upsells'), 'upsells', false));
          this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.upsells-statistics'), 'upsells-statistics', false));
        }
      }

      if (this.utilsService.hasPermission(Permissions.BOOKING_BOOKLET_REVIEW, null) && this.myCorporateOwner?.subscriptionName !== 'yaago_mission') {
        this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.booklet-reviews'), 'booklet-reviews', false));
      }
      if (this.utilsService.hasPermission(Permissions.BOOKING_DASHBOARD_ALERTS, null)) {
        this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.booking-alerts'), 'booking-alerts', false));
      }
      if (this.utilsService.hasPermission(Permissions.BOOKING_COMMUNICATION, null) && this.myCorporateOwner?.subscriptionName !== 'yaago_mission') {
        this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.guest-msg'), 'thread', false));
      }

      if (this.subItemsTabs.length > 0) {
        this.subItemsTabs.push(new NavMenuItem('sep', 2, false));
      }
      if (this.utilsService.hasPermission(Permissions.SETTINGS_CONNECTED_SERVICES, null) && this.myCorporateOwner?.subscriptionName !== 'yaago_mission') {
        this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.connected-services'), 'services', false));
      }

      if (this.utilsService.hasPermission(Permissions.PROPERTY_DASHBOARD_CONNECTED_DEVICES, null)) {
        if (this.myProfile?.corporate?.iglooHome != null || this.myProfile?.corporate?.theKeys != null
          || this.myProfile?.corporate?.minut != null || this.myProfile?.corporate?.nukiInformation != null) {
          this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.connected-device'), 'lockers', false));
        }
        if (this.myProfile?.corporate?.minut != null) {
          this.subItemsTabs.push(new NavMenuItem(this.translateService.instant('cc3-nav-bar.connected-minut'), 'minut', false));
        }
      }

    }


    if (this.myCorporateOwner?.subscriptionName === 'yaago_360' || this.myCorporate?.betaTesterForInbox) {
      const inboxSettings = new NavMenuItem(this.translateService.instant('cc3-nav-bar.inbox'), 'inbox', false)
      this.tabs.push(inboxSettings);
    }

    if (this.subItemsTabs && this.subItemsTabs.length > 0) {
      const menuSettings = new NavMenuItem(this.translateService.instant('cc3-nav-bar.more'), 3, false)
      menuSettings.subItems = this.subItemsTabs;
      this.tabs.push(menuSettings);
    }



  }

  getSubscriptionName() {
    if (this.myCorporateOwner?.subscriptionName === 'success') {
      return 'Success';
    } else if (this.myCorporateOwner?.subscriptionName?.startsWith('performance')) {
      return 'Performance';
    } else if (this.myCorporateOwner?.subscriptionName === 'premium') {
      return 'Premium';
    } else if (this.myCorporateOwner?.subscriptionName === 'yaago_360' || this.myCorporateOwner?.subscriptionName === 'yaago_360_eng') {
      return 'Yaago 360';
    } else if (this.myCorporateOwner?.subscriptionName === 'yaago_voyageur') {
      return 'Yaago Voyageur';
    } else if (this.myCorporateOwner?.subscriptionName === 'yaago_mission') {
      return 'Yaago Mission';
    }


  }

  resend_postmark() {
    this.corporateEndpoint.resendPostmarkEmail(this.myCorporate.id).subscribe((res ) => {
      this.myCorporate = res;
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('cc3-nav-bar.resend-postmark-done'), 'info'));
    }, err => {
      this.utilsService.handleYaagoErrorCode(err);
    });
  }

  bookingRequestCode(bookingDotComAccount: BookingDotComAccount) {
    this.loaderService.showFullLoader('');
    this.bookingDotComEndpoint.V3RequestChallengecode(bookingDotComAccount).subscribe( res => {
      this.loaderService.dismissLoader();
      if (res == null) {
        this.bookingDotComEndpoint.V3GetBookingDotComChallenge().subscribe( res => {
          this.bookingDotComChallenge = res;
        });
      } else {
        console.log("V3GetBookingDotComChallenge", res);
        this.demandalreadyasked = true;
      }
    });
  }

  bookingSendCode(bookingDotComAccount: BookingDotComAccount, code: string) {
    this.loaderService.showFullLoader('');
    const challengeSendCode: ChallengeSendCode = new ChallengeSendCode();
    challengeSendCode.code = code;
    challengeSendCode.bookingDotComAccount = bookingDotComAccount;
    this.bookingDotComEndpoint.V3ChallengeSendCode(challengeSendCode).subscribe( res => {
      this.loaderService.dismissLoader();
      console.log("V3GetBookingDotComChallenge", res);
      if (res.nextStep === 'STEP_SUCCESS') {
        this.bookingDotComEndpoint.V3GetBookingDotComChallenge().subscribe( res => {
          console.log("V3GetBookingDotComChallenge", res);
          this.bookingDotComChallenge = res;
        });
      }

    });
  }

  closeAll() {
    const tooltip: HTMLElement = document.getElementById('dropDownMenu');
    tooltip.style.display = 'none';
  }
  openProfile() {
    if (this.subItemTabSelected === this.subItemsProfileTabs) {
      this.subItemTabSelected = null;
     this.closeAll();
    } else {
      this.subItemTabSelected = this.subItemsProfileTabs;
      this.menuOpen = true;
      const tooltip: HTMLElement = document.getElementById('dropDownMenu');
      tooltip.style.position = 'absolute';
      tooltip.style.right = '32px';
      tooltip.style.left = 'unset';
      tooltip.scrollTop = 10;
      tooltip.style.display = 'block';
    }
  }
  openMenu(index: number, tab: NavMenuItem) {
    this.currentNavMenuItem = null;
    if (tab.subItems != null && this.isDesktopMode()) {
      this.subItemTabSelected = tab.subItems;
      if (!this.menuOpen) {
        console.log('openMenu');
        this.menuOpen = true;
        const tabItemId = 'tab' + index;
        const tabItem: HTMLElement = document.getElementById(tabItemId);
        console.log('tabItem', tabItem);
        console.log('tabItem', tabItem.getBoundingClientRect());
        console.log('tabItem left', tabItem.getBoundingClientRect().left);
        const tooltip: HTMLElement = document.getElementById('dropDownMenu');
        tooltip.style.position = 'absolute';
        tooltip.style.left = tabItem.getBoundingClientRect().left + 'px';
        tooltip.style.right = 'unset';
        tooltip.scrollTop = 10;
        tooltip.style.display = 'block';
        tooltip.style.width = 'fit-content';
        console.log('openMenu change left');
      } else {
        this.menuOpen = false;
        const tooltip: HTMLElement = document.getElementById('dropDownMenu');
        tooltip.style.display = 'none';
      }
    } else  {
      this.clickNavItem(tab);
      if (this.isDesktopMode()) {
        this.currentNavMenuItem = tab;
        this.subItemTabSelected = [];
        this.menuOpen = false;
        const tooltip: HTMLElement = document.getElementById('dropDownMenu');
        tooltip.style.display = 'none';
      } else {
        this.openFullMenu = false;
      }


      // Go to Click Navigation
    }
  }

  clickNavItem(navMenu: NavMenuItem) {
    if (navMenu.value === 'createAccountLink') {
      this.createStripeAccountLink();
    } else  if (navMenu.value === 'dashboard') {
      this.goToDashboard();
    } else  if (navMenu.value === 'calendar-tasks') {
      this.router.navigate(['/dashboard/task-calendar']).then(() => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'property') {
      this.goToMyProperties();
    } else if (navMenu.value === 'inbox') {
      this.goToInbox();
    } else if (navMenu.value === 'billing') {
      this.goToBilling();
    } else if (navMenu.value === 'bookings') {
      this.goToBookings();
    } else if (navMenu.value === 'generic-bookings') {
      this.goToGenericLinks();
    }  else if (navMenu.value === 'calendar') {
      this.loaderService.showFullLoader('');
      if (this.myProfile?.corporate?.multipleViewAsDefault) {
        this.router.navigate(['/calendar/month']).then(() => { this.loaderService.dismissLoader() });
      } else {
        this.router.navigate(['/calendar']).then(() => { this.loaderService.dismissLoader() });
      }
    } else if (navMenu.value === 'recommendation') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['/recommendation']).then(() => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'recommendation-list') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['/recommendation/list']).then(() => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'platforms') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['register/manage-platform']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'profile') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['setup/profile']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'assign') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['setup/assign-multiple']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'abonnement') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['setup/new-subscription']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'stats') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['setup/corporate-stats']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'support') {
      // this.loaderService.showFullLoader('');
      console.log('support');
      window.open('https://share.hsforms.com/1OlmeY0LuSYKVSdj8i2mpjg3u8cc');
      // this.router.navigate(['support']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'academy') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['setup/academy']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'referral') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['setup/referral']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'services') {
      this.loaderService.showFullLoader('');
      this.router.navigate(['setup/services-configuration']).then( () => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'logout') {
      this.firebaseService.signOut();
      this.personService.setCurrentUser(null);
      this.router.navigate(['/login']).then(() => { this.loaderService.dismissLoader() });
    } else if (navMenu.value === 'invitations') {
      this.router.navigate(['setup/invites']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'keyring') {
      this.router.navigate(['setup/keyring-setup']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'masterlock') {
      this.router.navigate(['setup/masterlock-setup']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'bulk-swikly') {
      this.router.navigate(['setup/bulk-swikly']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'bulk-igloohome') {
      this.router.navigate(['setup/bulk-igloohome']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'bulk-nuki') {
      this.router.navigate(['setup/bulk-nuki']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'lockers') {
      this.router.navigate(['dashboard/lockers']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'lockers') {
      this.router.navigate(['dashboard/lockers']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'upsells') {
      this.router.navigate(['dashboard/upsell-onboarding']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'upsells-statistics') {
      this.router.navigate(['dashboard/upsell-statistics']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'thread') {
      this.router.navigate(['dashboard/thread']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'minut') {
      this.router.navigate(['dashboard/minut']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'booking-alerts') {
      this.router.navigate(['dashboard/booking-alerts']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'booklet-reviews') {
      this.router.navigate(['dashboard/booklet-reviews']).then(() => this.loaderService.dismissLoader());
    }else if (navMenu.value === 'checkin-online') {
      this.router.navigate(['dashboard/checkin-online']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'locker') {
      this.router.navigate(['setup/locker-setup']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'roles') {
      this.router.navigate(['setup/role-users/roles']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'users') {
      this.router.navigate(['setup/role-users/users']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'new-users') {
      this.router.navigate(['setup/newusers']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'teams') {
      this.router.navigate(['setup/team']).then(() => this.loaderService.dismissLoader());
    } /*else if (navMenu.value === 'settings') {
      this.router.navigate(['setup/settings']).then(() => this.loaderService.dismissLoader());
    }*/ else if (navMenu.value === 'accommodation-settings') {
      this.router.navigate(['setup/settingsv2/accommodations']).then(() => this.loaderService.dismissLoader());
    } else if (navMenu.value === 'messages-settings') {
      this.router.navigate(['setup/settingsv2/messages']).then(() => this.loaderService.dismissLoader());
    }

  }

  goToMyProperties() {
    if (this.utilsService.hasPermission(Permissions.PROPERTY_LISTING, null)) {
      this.loaderService.showFullLoader('');
      //if (this.myProfile?.corporate?.multipleViewAsDefault && this.isDesktopMode()) {
        this.router.navigate(['/property/multiple']).then(() => {
          this.loaderService.dismissLoader()
        });
      /*} else {
        this.router.navigate(['/property']).then(() => {
          this.loaderService.dismissLoader()
        });
      }*/
      this.currentNavMenuItem = this.tabs.filter(t => t.value === 'property')[0];
    }

  }

  goToDashboard() {
    this.openFullMenu = false;
    this.router.navigate(['/dashboard']).then(() => {
    });
  }

  goToInbox() {
    this.openFullMenu = false;
    this.router.navigate(['/dashboard//inbox']).then(() => {
    });
  }

  goToBilling() {
    this.openFullMenu = false;
    this.router.navigate(['/dashboard//billing']).then(() => {
    });
  }

  goToBookings() {
    this.openFullMenu = false;
    this.router.navigate(['/dashboard/bookings']).then(() => {
    });
  }

  goToGenericLinks() {
    this.openFullMenu = false;
    this.router.navigate(['/dashboard/generic-link']).then(() => {
    });
  }

  goToMyAccount() {
    this.openFullMenu = false;
    this.router.navigate(['setup/profile']).then( () => { this.loaderService.dismissLoader() });
  }

  goToRecommendation() {
    this.openFullMenu = false;
    this.loaderService.showFullLoader('');
    this.router.navigate(['/recommendation']).then(() => { this.loaderService.dismissLoader() });
  }

  createStripeAccountLink() {
    this.corporateEndpoint.createStripeAccountLink(this.myProfile.corporate.id).subscribe( returnUrl => {
      if (returnUrl != null) {
        window.open(returnUrl.url);
      }
    });
  }

  goToCalendarSimple() {
    this.openFullMenu = false;
    this.loaderService.showFullLoader('');
    this.router.navigate(['/calendar']).then(() => { this.loaderService.dismissLoader() });
  }

  goToPropertySimple() {
    this.openFullMenu = false;
    this.loaderService.showFullLoader('');
    this.router.navigate(['/property']).then(() => { this.loaderService.dismissLoader() });
  }

  openMobilePlusMenu() {
    this.openFullMenu = !this.openFullMenu;

  }

  yaago_subscribe() {
    this.personEndpoint.getStripeClientPortal('#/property').subscribe( returnUrl => {
      // const windowSize = 'width=' + window.innerWidth + ',height=' + window.innerHeight + ',scrollbars=no';
      window.open(returnUrl.url);
      // , 'popup', windowSize);
    });
  }

  yaago_onboarding() {
    window.open('https://meetings.hubspot.com/remi-cannessant/customer-onboarding-', '_blank');
  }

  openFaq() {
    window.open('https://yaago.crisp.help/fr/', '_blank');
  }

manageChangeLanguage(languageCode: string) {
  this.changeMyLanguage(languageCode);
  //this.manageTabs();
}
  getLanguages() {
    return UtilsService.languageArrayForWebApp;
  }

  getNonTranslatedLanguageName( languageCode: string) {
    switch (languageCode) {
      case 'fr':
        return 'Français';
      case 'en':
        return 'English';
      case 'de':
        return 'Deutsch';
      case 'es':
        return 'Español';
      case 'it':
        return 'Italiano';
      case 'nl':
        return 'Nederlands';
      case 'pt':
        return 'Português';
      case 'da':
        return 'Dansk';
      case 'no':
        return 'Norsk';
      case 'ru':
        return 'русский';
      case 'pl':
        return 'Polskie';
      case 'sv':
        return 'Svenska';
      case 'tr':
        return 'Türk';
      case 'zh':
        return '中国人';
      case 'ja':
        return '日本人';
    }
  }

  isVatConcern() :boolean {
    let ret = false;
    if (this.myCorporateOwner != null && this.myCorporateOwner.country != null) {
      if (this.utilsService.yaagoEuropeanCountries) {
        if (this.utilsService.yaagoEuropeanCountries.indexOf(this.myCorporateOwner.country) > -1
          && (!this.myCorporate.vatNumber || this.myCorporate.vatNumber === '')) {
          ret = true;
        }
      }
    }
    return ret;
  }

}

export class NavMenuItem {
  title: string;
  value: any;
  selected: boolean;
  badge: boolean;
  disabled: boolean;

  subItems: NavMenuItem[];
  onlyDesktop: boolean;
  bold: boolean;

  constructor(title: string, value: any, selected = false, disabled = false, onlyDesktop = false, tabBold = false) {
    this.title = title;
    this.value = value;
    this.selected = selected;
    this.disabled = disabled;
    this.badge = false;
    this.subItems = null;
    this.onlyDesktop = onlyDesktop;
    this.bold = tabBold;
  }




}
